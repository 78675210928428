@font-face {
  font-family: "Poppins-Bold";
  src: url('./fonts/Poppins-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url('./fonts/Poppins-Medium.ttf');
  font-weight: 600;
}
@font-face {
  font-family: "Poppins-Regular";
  src: url('./fonts/Poppins-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: "Urbane-Bold";
  src: url('./fonts/Urbane-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: "Urbane-DemiBold";
  src: url('./fonts/Urbane-DemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: "Urbane-Medium";
  src: url('./fonts/Urbane-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: "Urbane-Regular";
  src: url('./fonts/Urbane-Light.ttf');
  font-weight: 400;
}
@font-face {
  font-family: "Roboto-Medium";
  src: url('./fonts/Roboto-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: "Roboto-Regular";
  src: url('./fonts/Roboto-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url('./fonts/Roboto-Bold.ttf');
  font-weight: bold;
}

table.sl-table {
  color: #fff;
  padding-bottom: 0;
  margin-bottom: 0;
  border-collapse: unset;
  border-spacing: 0 0.65rem;
  tr {
    background-color: #1F2127;
    &:last-child td {
      border-bottom: none;
    }
  }
  tbody tr {
    &:hover {
      background-color: #2D2F37;
      color: #fff;
    }
  }
  &.join-tb tbody tr {
    &:hover {
      background: linear-gradient(180deg, #649927 0%, #7AC02B 100%);
      color: #1F2127;
    }
  }
  th {
    border-bottom: 1px solid #7ABF2B !important;
    font-size: 1rem;
  }
  tr {
    color: #9A9EAA;
    font-family: "Roboto-Medium";
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 7px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 7px;
  }
  tr:first-child th:first-child {
    border-top-left-radius: 7px;
  }
  tr:last-child th:last-child {
    border-top-right-radius: 7px;
  }
  tr th {
    font-family: "Roboto-Medium";
    padding: 25px 20px;
    font-size: 0.9rem;
    &:first-child {
      padding: 25px 5px 25px 10px;
    }
    &:nth-child(2) {
      padding: 25px 0;
    }
  }
  tr td {
    border-top: 1px solid #090A0D;
    font-size: 0.8rem;
    vertical-align: middle;
    padding: 15px 20px;
    box-sizing: border-box;
    &:first-child {
      padding: 15px 0 15px 10px;
    }
    &:nth-child(2) {
      padding: 15px 0;
    }
  }
}
.table>:not(caption)>*>* {
  border-bottom-width: 0;
}

#WEB3_CONNECT_MODAL_ID, .web3modal-modal-lightbox {
    z-index: 999999 !important;
}
