.terms-section {
  min-height: 100vh;
  background-color: #1f1f1f;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 20px;
  padding-bottom: 20px;
  .content {
    background: rgba(2, 5, 9, 0.5);
    backdrop-filter: blur(30px);
    border-radius: 12px;
    padding: 30px;
    h2 {
      color: #fff;
      font-family: "Roboto-Bold";
      font-size: 21px;
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      font-family: "Roboto-Regular";
      color: #fff;
      font-size: 14px;
    }
  }
}
