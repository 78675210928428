.avatar {
  justify-content: left;
  display: flex;
  img {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
  }
  span {
    color: #EBA53C;
  }
  p {
    color: #9A9EAA;
    font-family: "Roboto-Regular";
    font-size: 13px;
    text-align: left;
    
  }
}
