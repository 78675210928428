$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

.upcoming-section {
  background-color: #1f1f2c;
  color: #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  padding-top: 15px;
  @media(min-width: $lg) {
    height: calc(100vh - 85px);
  }
  .tournament-info {
    background: rgba(2, 5, 9, 0.3);
    text-align: center;
    border-radius: 8px;
    padding: 45px 20px;
    position: relative;
    z-index: 99999;
    backdrop-filter: blur(5px);
    height: 100%;
    margin-top: 20px;
    @media(min-width: $lg) {
      height: calc(100vh - 150px);
      margin-top: unset;
    }
    .info-item {
      margin-bottom: 40px;
      svg {
        width: 1.2rem;
        margin-bottom: 10px;
      }
      .first-title {
        font-size: 0.9rem;
        color: #fff;
        font-family: "Roboto-Regular";
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 30px;
          height: 3px;
          width: 30%;
          border-radius: 10px;
          background-color: #7ABF2B;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .second-title {
        @extend .first-title;
        font-family: "Roboto-Bold";
        font-size: 1.1rem;
        &:after {
          width: 20%;
        }
      }
      .third-title {
        @extend .second-title;
        font-family: "Roboto-Medium";
        &:after {
          width: 10%;
          top: 4rem;
        }
      }
      .sub-title {
        color: rgba(255, 255, 255, 0.5);
        font-size: 0.7rem;
        display: inline-block;
        margin-bottom: 0.8rem;
      }
      .clock {
        letter-spacing: 0.7rem;
        font-size: 0.7rem;
        margin-left: 0.6rem;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .tournament-list-bg {
    padding: 0 0.65rem;
    border-radius: 8px;
    background: #0205094D;
    max-height: 700px;
    margin-bottom: 10rem;
    backdrop-filter: blur(5px);
    position: relative;
    z-index: 90000;
    overflow-x: scroll;
    overflow-y: scroll;
    margin-top: 32px;
    @media(min-width: $lg) {
      height: calc(100vh - 150px);
      margin-bottom: unset;
      overflow-x: hidden;
      margin-top: 0;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #659A28;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .bg {
    position: fixed;
    width: 550px;
    height: auto;
    bottom: 0;
    z-index: 999;
    left: 5%;
    img {
      width: 100%;
      height: auto;
    }
  }
}

#scroll-hint {
  height: 70px;
  width: 70px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  left: calc(50% - 35px);
  top: 43%;
  background-color: #00000096;
  @media(min-width: 992px) {
    display: none;
  }
}
