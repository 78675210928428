.winning-popup-wrapper {
  display: none;
  position: absolute;
  z-index: 9999999;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, #649927 0%, #7AC02B 100%);
  box-shadow: 0 3px #375516;
  border-radius: 40px;
  .popup-content {
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 7px 7px 7px 30px;
    h2 {
      padding-bottom: 0;
      margin-bottom: 0;
      font-family: "Roboto-Bold";
      font-size: 25px;
    }
    span {
      font-family: "Roboto-Regular";
      font-size: 13px;
      white-space: nowrap;
    }
    .btn {
      background-color: #fff;
      color: #1D1E25;
      display: flex;
      flex-direction: column;
      border-radius: 26px;
      padding-left: 25px;
      padding-right: 25px;
      span {
        font-family: "Roboto-Regular";
        font-size: 13px;
        color: #1D1E25;
        b {
          font-family: "Roboto-Bold";
        }
      }
    }
  }
  &.active {
    display: block;
  }
}
