.popup-wrapper {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  display: none;
  &.active {
    display: block;
  }
  .popup-content {
    max-width: 700px;
    margin: auto;
    background-color: #181A1F;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    border-radius: 9px;
    .popup-body {
      .head {
        padding: 12px 20px;
        background-color: #1F2127;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 9px 9px 0 0;
        border-bottom: 1px solid #090A0D;
        h4 {
          padding-bottom: 0;
          margin-bottom: 0;
          font-size: 15px;
        }
        span {
          font-size: 25px;
          cursor: pointer;
        }
      }
      p {
        text-align: center;
        margin: 32px 0;
      }
      .input {
        margin-bottom: 32px;
        input {
          display: block;
          width: 100%;
          height: 60px;
          border-radius: 8px;
          border: 1px solid #090A0D;
          padding: 8px 20px;
          background-color: #1F2127;
          outline: none;
          color: #fff;
          font-family: "Roboto-Regular";
          &::placeholder {
            font-family: "Roboto-Regular";
          }
        }
      }
      .input, .buttons {
        padding: 0 25px
      }
      .buttons {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
      }
      hr {
        margin: 0 25px;
        color: #090A0D;
        background-color: #090A0D;
      }
    }
  }
}
