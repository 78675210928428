$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

.game-section {
  background-color: #1f1f1f;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  padding-top: 15px;
  @media(min-width: $lg) {
    height: calc(100vh - 85px);
  }
  .chat-col {
    width: 100%;
    order: 2;
    @media(min-width: $lg) {
      width: 21%;
    }
  }
  .wheel-col {
    width: 100%;
    order: 1;
    @media(min-width: $lg) {
      width: 58%;
    }
  }
  .leaderboard-col {
    width: 100%;
    order: 3;
    @media(min-width: $lg) {
      width: 21%;
    }
  }
  .bg {
    position: fixed;
    width: 550px;
    height: auto;
    bottom: 0;
    z-index: 999;
    left: 5%;
    img {
      width: 100vh;
      height: auto;
    }
  }
  .chat-container {
    background: rgba(2, 5, 9, 0.3);
    text-align: center;
    border-radius: 8px;
    position: relative;
    z-index: 99999;
    backdrop-filter: blur(5px);
    height: 300px;
    margin-bottom: 1rem;
    @media(min-width: $lg) {
      height: calc(100vh - 150px);
      margin-bottom: unset;
    }
    .chat-head {
      background-color: #1F2127;
      color: #fff;
      font-family: "Roboto-Medium";
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #090A0D;
      padding: 15px 24px;
      border-radius: 5px 5px 0 0;
      align-items: center;
      h3 {
        font-size: 16px;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      span {
        font-size: 13px;
        font-family: "Roboto-Regular";
      }
      .online {
        color: #9A9EAA;
        margin-right: 10px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          right: -8px;
          top: 4px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #7ABF2B;
        }
      }
    }
    .chat-body {
      padding: 20px;
      height: calc(300px - 205px);
      overflow-y: scroll;
      overflow-x: hidden;
      @media(min-width: $lg) {
        height: calc(100vh - 150px - 209px);
      }
      &::-webkit-scrollbar {
        width: 4px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #659A28;
        border-radius: 10px;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
    .chat-input {
      background-color: #14161C;
      border-top: 1px solid #090A0D;
      height: 150px;
      border-radius: 0 0 8px 8px;
      margin: 2px;
      position: relative;
      textarea {
        width: 100%;
        border: none;
        background-color: #14161C;
        resize: none;
        border-radius: 0 0 8px 8px;
        outline: none;
        color: #9A9EAA;
        font-family: "Roboto-Regular";
        font-size: 13px;
        padding: 10px;
        height: calc(100% - 35px);
      }
      button {
        position: absolute;
        right: 10px;
        top: 20px;
        padding: 0 8px;
        background: linear-gradient(180deg, #649927 0%, #7AC02B 100%);
        height: 80px;
        border-radius: 22px;
        border: none;
        box-shadow: 0 6px 6px #28410C;
        &:hover {
          opacity: .9;
        }
      }
    }
    .emoji-box {
      display: flex;
      justify-content: space-between;
      height: 35px;
      padding: 0 10px;
      color: #9A9EAA;
      font-family: "Roboto-Regular";
      font-size: 13px;
    }
  }
  .game-content {
    position: relative;
    .img {
      width: 100%;
      background-size: cover;
      background-position: center center;
      height: auto;
      padding: 30px 0;
      border-radius: 10px;
      position: relative;
      z-index: 900000;
      display: flex;
      justify-content: center;
      align-items: center;
      @media(min-width: $lg) {
        height: calc(100vh - 350px);
        padding: 0;
      }
    }
    &.bloor .img:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background: linear-gradient(180deg, #181A1F 0%, rgba(24, 26, 31, 0.6) 63.02%);
    }
    .game-items {
      height: auto;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 4px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #659A28;
        border-radius: 10px;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      @media(min-width: $lg) {
        height: 169px;
      }
    }
    .row {
      margin-top: 20px;
      .col-lg-6:first-child .item {
        border-radius: 10px 10px 0 0;
      }
      .col-lg-6:nth-child(2) .item {
        border-radius: 10px 10px 0 0;
      }
      .col-lg-6:nth-last-child(2) .item {
        border-radius: 0 0 10px 10px ;
      }
      .col-lg-6:last-child .item {
        border-radius: 0 0 10px 10px;
      }
      .item {
        background: #181A1F;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        color: #9A9EAA;
        font-family: "Roboto-Medium";
        position: relative;
        z-index: 9999;
        padding: 18px 15px;
        align-items: center;
        margin-bottom: 10px;
        height: 65px;
        .round {
          border-right: 1px solid #090A0D;
          padding-right: 10px;
          display: block;
          width: 23%;
          white-space: nowrap;
        }
        .buttons {
          margin-left: 10px;
          width: 77%;
          .inputs {
            display: flex;
            position: relative;
            .input-box {
              display: flex;
              width: 100%;
              position: relative;
              input {
                border: 1px solid #0F1015;
                width: 100%;
                margin-right: 18px;
                background-color: #14161C;
                color: #fff;
                font-family: "Roboto-Medium";
                font-size: 15px;
                border-radius: 18px;
                outline: none;
                height: 38px;
                padding: 8px 30px 8px 8px;
                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                /* Firefox */
                &[type=number] {
                  -moz-appearance: textfield;
                }
              }
              .actions {
                display: flex;
                position: absolute;
                right: 1.4rem;
                top: 0.2rem;
                button {
                  background: linear-gradient(180deg, #2167A8 0%, #2979C2 100%);
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  outline: none;
                  border: none;
                  box-shadow: 0 2px #134472;
                  transition: all 0.4s;
                  &:hover {
                    opacity: 0.8;
                  }
                }
              }
            }
            .spin {
              background: linear-gradient(180deg, #649927 0%, #7AC02B 100%);
              outline: none;
              border: none;
              color: #fff;
              font-family: "Roboto-Medium";
              font-size: 14px;
              padding: 4px 18px;
              text-transform: uppercase;
              border-radius: 25px;
              box-shadow: 0 2px #375516;
              transition: all 0.4s;
              &:hover {
                opacity: 0.8;
              }
              &:disabled {
                background: linear-gradient(180deg, #34363D 0%, #1F2127 100%);
                box-shadow: 0 6px 6px #080A0C;
                div, span {
                  opacity: 0.1;
                }
              }
            }
          }
        }
      }
    }
  }
  .table-container {
    height: 400px;
    background: rgba(2, 5, 9, 0.4);
    backdrop-filter: blur(20px);
    border-radius: 7px;
    padding: 0 8px;
    margin-bottom: 5rem;
    overflow-y: scroll;
    overflow-x: scroll;
    position: relative;
    z-index: 999999;
    @media(min-width: $lg) {
      height: calc(100vh - 150px);
      margin-bottom: unset;
    }
    @media(min-width: $xl) {
      overflow-x: hidden;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #659A28;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.nav-tabs {
  margin-top: 12px;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #6fad28;
  .nav-link {
    color: #fff;
    font-family: "Urbane-DemiBold";
    &:hover {
      border-color: #6fad28;
    }
    &.active {
      background-color: #6ba528;
      color: #fff;
      border-color: transparent;
    }
  }
}
