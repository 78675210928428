$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

.navigation-bar {
  background-color: #181821 !important;
  color: #FFFFFF !important;
  font-family: 'Poppins-Regular';
  border-bottom: 1px solid #ffffff40;
  padding: 20px 0;
  z-index: 99999;
  @media (min-width: $lg) {
      padding: 0;
  }
  .nav-link {
    color: #FFFFFF !important;
  }
  .navbar-brand {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .navbar-nav {
    .nav-link {
      font-size: 13px;
      padding: 22px 10px;
      border-top: 3px solid transparent;
      cursor: pointer;
      transition: all .4s;
      &.active, &:hover {
        border-top: 3px solid #79BE2B;
      }
      @media(min-width: $xl) {
        font-size: 14px;
        padding: 30px 10px;
      }
    }
  }
  .navbar-toggler {
    background: white;
    outline: none;
  }
}
