$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

@keyframes rotation {
  0% {
    transform: rotate(0deg) scale(1.3);
  }
  100% {
    transform: rotate(360deg) scale(1.3);
  }
}

.images-cont {
  display: flex;
  position: relative;
  .front-image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    img {
      transform: scale(1.3);
      &.spin {
        animation: rotation 0.8s 1 ease-out;
      }
    }
  }
  .back-image-right {
    img {
      transform: rotate(180deg);
    }
  }
  img {
    width: 100%;
    max-width: 500px;
  }
}

.main-section {
  background-color: #20282d;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  @media(min-width: $lg) {
    height: 100vh;
  }
  .buttons {
    button {
      @media(max-width: $md) {
        display: block;
        margin-left: 0 !important;
      }
    }
    button:last-child {
      @media(max-width: $md) {
        margin-top: 10px !important;
      }
    }
  }
  .bitcoin-main {
    max-width: 200px;
    position: absolute;
    left: -100px;
    bottom: 0;
  }
  .container {
    height: 100%;
    .row {
      height: 100%;
      align-items: center;
    }
  }
  .left-cont {
    margin-top: 10rem;
    @media(min-width: $lg) {
      margin-top: -50px;
    }
    .line {
      color: #79BE2B;
      font-size: 13px;
      text-transform: uppercase;
      font-family: "Poppins-Bold";
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100px;
        height: 1px;
        background-color: #79BE2B;
        top: 8px;
        margin-left: 20px;
      }
    }
    h2 {
      font-family: "Urbane-Bold";
      font-size: 40px;
      color: #fff;
      margin-top: 12px;
      margin-bottom: 22px;
    }
    p {
      font-family: "Urbane-Regular";
      font-size: 13px;
      color: #fff;
      margin-right: 100px;
    }
  }
  .right-cont {
    position: relative;
    margin-top: 10rem;
    margin-bottom: 10rem;
    @media(min-width: $lg) {
      margin-top: unset;
      margin-bottom: unset;
    }
    .bitcoin {
      position: absolute;
      max-width: 200px;
      width: 100%;
      margin-top: -150px;
      visibility: hidden;
      @media(min-width: $xl) {
        visibility: visible;
      }
    }
  }
  .arrow-down {
    position: absolute;
    bottom: 50px;
  }
}

.how-to-play {
  background-color: #151b16;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  scroll-margin-top: 85px;
  padding: 40px 0;
  .connect-box {
    max-width: 700px;
    margin: auto;
    align-items: center;
    background: #131517;
    border: 1px solid #ffffff40;
    border-radius: 46px;
    color: #fff;
    padding: 8px;
    .key {
      img {
        width: 42px;
      }
    }
    .text {
      padding-bottom: 0;
      margin-bottom: 0;
      font-size: 13px;
      margin-left: 10px;
      font-family: "Urbane-Regular";
    }
    .s-button {
      margin: 0;
      margin-left: auto;
      margin-top: -2px;
    }
  }
  .game-steps {
    margin-top: 40px;
    margin-bottom: 100px;
    justify-content: space-around;
    div {
      font-family: "Urbane-DemiBold";
      font-size: 30px;
      color: #fff;
      @media(max-width: $md) {
        font-size: 12px;
      }
    }
    svg {
      @media(max-width: $md) {
        width: 9px;
        height: 9px;
      }
    }
  }
  .images-cont {
    text-align: center;
  }
  .bottom-text {
    color: #fff;
    font-family: "Urbane-Regular";
    font-size: 14px;
    margin: 100px 130px 0;
    text-align: center;
    opacity: .5;
  }
}

.leaderboard-cont {
  background-color: rgb(32,28,44);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  scroll-margin-top: 85px;
  padding: 120px 0;
  .left-cont {
    .table-cont {
      border: 1px solid #ffffff40;
      border-radius: 10px;
      h3 {
        color: #fff;
        text-align: center;
        font-family: "Urbane-Regular";
        font-size: 32px;
        margin-top: 25px;
      }
      .table-lists {
        background-color: #181A1F;
        border-radius: 8px;
        margin: 20px 10px 0 10px;
        .row {
          background: #1F2127;
          margin: 0;
          margin-bottom: 6px;
          color: #9A9EAA;
          text-align: center;
          font-family: "Roboto-Medium";
          padding: 18px 0;
          border-top: 1px solid #090A0D;
          &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            padding: 22px 0;
            border-bottom: 1px solid #68a127;
            color: #fff;
            border-top: 0;
          }
          &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }
  .right-cont {
    h2 {
      font-family: "Urbane-Bold";
      font-size: 45px;
      color: #fff;
      margin-top: 40px;
    }
    p {
      font-family: "Poppins-Regular";
      color: #fff;
      font-size: 13px;
    }
    .s-button {
      margin-top: 20px;
    }
  }
}

.faq {
  background-color: rgb(32,28,44);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  scroll-margin-top: 85px;
  padding: 140px 0;
  .title {
    color: #fff;
    text-align: center;
    font-family: "Urbane-Bold";
    margin-bottom: 32px;
  }
  .accordion-item {
    border: 0;
    .accordion-header {
      border-bottom: 1px solid #090A0D;
      .accordion-button {
        outline: none;
        background: #181A1F;
        color: #fff;
        font-family: "Urbane-Bold";
        font-size: 15px;
        padding-top: 30px;
        padding-bottom: 30px;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .accordion-collapse {
    background-color: #181A1F;
    color: #fff;
    font-size: 13px;
    font-family: "Poppins-Regular";
    line-height: 24px;
  }
  .accordion-button:not(.collapsed) {
    background: red;
  }
  .accordion-button::after {
    background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white"/></svg>');
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url('data:image/svg+xml,<svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="white"><rect height="64" width="384" x="64" y="224" /></svg>');
  }
}

.contact {
  background-color: rgb(32, 28, 44);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 140px;
  scroll-margin-top: 85px;
  .title {
    font-family: "Urbane-Bold";
    font-size: 45px;
    color: #fff;
  }

  p {
    font-size: 13px;
    font-family: "Poppins-Regular";
    line-height: 24px;
    color: #fff;
  }

  input, textarea {
    background: #181A1F;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-family: "Poppins-Medium";
    font-size: 14px;
    resize: none;
    padding-left: 25px;

    &:focus {
      background-color: #181A1F;
      border: none;
      outline: 0;
      box-shadow: none;
      color: #fff;
    }

    &::placeholder {
      color: #fff;
    }
  }
  input {
    height: 65px;
  }
  textarea {
    padding: 25px 0 0 25px;
  }
  .sub-title {
    font-family: "Urbane-Bold";
    font-size: 40px;
    color: #fff;
    margin-top: 180px;
  }
  .arrow-down {
    margin-left: auto;
  }
  .buttons {
    margin-top: 40px;
    button {
      @media(max-width: $md) {
        margin-left: 0 !important;
      }
    }
    button:last-child {
      @media(max-width: $md) {
        margin-top: 10px !important;
      }
    }
  }
  .footer {
    margin-top: 50px;
    border-top: 1px solid #22242A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    div {
      font-size: 13px;
      font-family: "Urbane-Medium";
      line-height: 24px;
      color: #fff;
    }
  }
}
