.s-button {
  position: relative;
  color: #fff;
  text-decoration: none;
  margin: auto;
  border-radius: 23px;
  font-size: 13px;
  outline: none;
  border: 0;
  font-family: "Urbane-DemiBold";
  &:active, &:hover {
    opacity: 0.8;
  }
  &.connect {
    background: linear-gradient(180deg, #1749A9 0%, #1F6BFF 100%);
    padding: 10px 25px;
    box-shadow: 0 3px #143F94;
    border-radius: 33px;
  }
  &.dark {
    background: linear-gradient(180deg, #34363D 0%, #1F2127 100%);
    padding: 16px 45px;
    box-shadow: 0 3px #16181D;
    border-radius: 33px;
    &.shadow:after {
      content: "";
      width: 100%;
      height: calc(100% + 5px);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0px 6px 6px #080A0C;
    }
  }
  &.primary {
    background: linear-gradient(180deg, #649927 0%, #7AC02B 100%);
    padding: 16px 45px;
    box-shadow: 0 3px rgba(119, 185, 43, 0.14);
  }
  &.join, &.send {
    background: linear-gradient(180deg, #649927 0%, #7AC02B 100%);
    padding: 16px 45px 16px 30px;
    box-shadow: 0 3px #375516;
    border-radius: 33px;
    position: relative;
    div {
      position: absolute;
      display: inline-flex;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #fff;
      top: 50%;
      transform: translateY(-50%);
      border: 2px solid #8aba54;
      img {
        margin: auto;
        width: 9px;
      }
    }
    span {
      margin-right: 10px;
      display: inline-block;
      text-align: left;
      font-size: 12px;
    }
    &.btn-small {
      padding: 10px 38px 10px 13px;
      white-space: nowrap;
      @media(min-width: 992px) {
        padding: 13px 45px 13px 30px;
      }
    }
    &:disabled {
      background: linear-gradient(180deg, #34363D 0%, #1F2127 100%);
      box-shadow: 0 6px 6px #080A0C;
      div, span {
        opacity: 0.1;
      }
    }
  }
  &.send {
    padding: 16px 55px;
    span {
      margin-right: 0;
    }
    svg {
      margin-left: 10px;
    }
  }
  &.twitter {
    background: linear-gradient(180deg, #327EBF 0%, #4AA1EB 100%);
    padding: 13px 62px;
    box-shadow: 0 3px #1C5789;
    border-radius: 33px;
    svg {
      margin-right: 10px;
      position: relative;
      top: -1px;
    }
  }
  &.discord {
    background: linear-gradient(180deg, #56669E 0%, #768AD4 100%);
    padding: 13px 62px;
    box-shadow: 0 3px #596AA5;
    border-radius: 33px;
    svg {
      margin-right: 10px;
    }
  }
}

.arrow-down {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  cursor: pointer;
  opacity: .7;
  transition: all .4s;
  &.up {
    transform: rotate(180deg);
  }
  img {
    margin: auto;
    width: 10px;
    height: auto;
  }
  &:hover {
    opacity: 1;
  }
}
